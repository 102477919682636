<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <form class="forms-sample" @submit.stop.prevent="onSubmit" autocomplete="off">
            <div class="row">
                <div class="col-md-12 d-flex align-items-stretch grid-margin">
                    <div class="row flex-grow">
                        <div class="col-12 grid-margin">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">{{ $t('Sightings: Category Specific') }}</h4>
                                </div>
                                <div class="card-body new-card-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <b-form-group :label="$t('Survey Name*')" label-for="project_name">
                                                <b-form-select 
                                                    v-model="selectedProjectId" 
                                                    :state="$v.form.project_id.$dirty ? !$v.form.project_id.$error : null" 
                                                    aria-describedby="input-1-live-feedback" 
                                                    :options="projectOptions" 
                                                    value-field="id" 
                                                    text-field="name"
                                                    @change="onProjectChange">
                                                    <b-form-select-option v-if="projectOptions.length === 0" :disabled="true">
                                                        {{ $t('No Project Found') }}
                                                    </b-form-select-option>
                                                </b-form-select>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-4">
                                            <b-form-group :label="$t('survey-style*')" label-for="survey_style_name">
                                                <b-form-input
                                                    type="text"
                                                    id="survey_style_name"
                                                    name="survey_style_name"
                                                    :placeholder="$t('survey-style')"
                                                    v-model="form.survey_style_name" 
                                                    readonly>
                                                </b-form-input>
                                            </b-form-group>
                                        </div>
                                         <!-- <div class="col-md-4">
                                            <b-form-group :label="$t('project-id*')" label-for="project_id">
                                                <b-form-input
                                                    type="text"
                                                    id="project_id"
                                                    name="project_id"
                                                    autocomplete="new-password"
                                                    :placeholder="$t('project-id')"
                                                    v-model="form.project_id" 
                                                    :state="$v.form.project_id.$dirty ? !$v.form.project_id.$error : null"
                                                    readonly>
                                                </b-form-input>
                                            </b-form-group>
                                        </div>-->
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <b-form-group :label="$t('category*')" label-for="Category">
                                                <b-form-select 
                                                    :options="categories" 
                                                    value-field="id" 
                                                    text-field="name" 
                                                    v-model="selectedCategoryId"
                                                    @change="handleCategoryChange">
                                                    <b-form-select-option v-if="categories.length === 0" :disabled = true>{{ $t('no-category-found') }}</b-form-select-option>
                                                </b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <label for="Species">{{ $t('Use') }}</label>
                                    <div class="row project-checkbox">
                                        <div class="col-lg-8">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <input type="checkbox" v-model="form.is_species_usable">
                                                    <label for="Species">{{ $t('Species') }}</label>
                                                </div>
                                                <div class="col-md-6">
                                                    <b-form-group label-for="Species">
                                                        <b-form-select 
                                                            v-model="form.species" 
                                                            aria-describedby="input-1-live-feedback" 
                                                            :options="Species" 
                                                            value-field="id" 
                                                            text-field="name">
                                                            <b-form-select-option v-if="Species.length === 0" :disabled="true">
                                                                {{ $t('No Species Found') }}
                                                            </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3">
                                                    <b-button class="btn btn-success" @click="toggleInput('Species')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 mt-4">
                                                    <input type="checkbox" v-model="form.is_behavior_usable">
                                                    <label for="behaviors">{{ $t('Behavior') }}</label>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <b-form-group label-for="behaviors">
                                                        <b-form-select 
                                                            v-model="form.behavior" 
                                                            aria-describedby="input-1-live-feedback" 
                                                            :options="behaviors" 
                                                            value-field="id" 
                                                            text-field="name">
                                                            <b-form-select-option v-if="behaviors.length === 0" :disabled="true">
                                                                {{ $t('No Behavior Found') }}
                                                            </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                    <b-button class="btn btn-success" @click="toggleInput('behaviors')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <b-form-textarea 
                                                        v-model="inputValue" 
                                                        :placeholder="$t('Enter values separated by commas')" 
                                                        rows="5">
                                                    </b-form-textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row">
                                        <div class="col-md-6"></div>
                                        <div class="col-md-6 mt-3">
                                            <b-button type="submit" variant="success" :disabled="isDisabled" class="btn float-right btn-success">
                                                <span>{{ $t('submit-btn') }}</span>
                                            </b-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </section>
</template>


<script>
    import Vue from "vue";
    import API from '@/api'
    import Snotify, {SnotifyPosition} from 'vue-snotify'
    import {validationMixin} from "vuelidate";
    import {required, minLength, survey_style, requiredIf, sameAs} from "vuelidate/lib/validators";
    import moment from 'moment';
    import i18n from '@/i18n';
    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
    export default {
        name: 'Users',
        mixins: [validationMixin],
        data() {
            return {
                form: {
                    id: '',
                    project_name: '',
                    project_id: '',
                    client_url: "",
                    survey_style_id: '',
                    survey_style_name: '',
                    species: '',
                    behavior: '',
                    is_species_usable: false,
                    is_behavior_usable: false,
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                },
                isDisabled: false,
                isLoading: false,
                rows: [],
                rowsForBulkEdit: [],
                totalRecords: 0,
                orders: [],
                projectOptions: [],
                Species: [],
                behaviors: [],
                categories: [],
                selectedProjectId: null,
                selectedCategoryId: null,
                showInput: false,
                inputValue: '',
                currentField: '',
            };
        },

        validations: {
            form: {
                id: {},
                project_name: {
                    required,
                }, 
                project_id: {
                    required,
                },
                survey_style_id: {
                    required,
                },
                selectedCategoryId: {
                    required,
                }
            },
        },
        async mounted() {
            this.form.id = this.$route.params.id;
            if (this.form.id && this.form.id !== 'undefined') {
                this.readonly = true;
                this.getSpotter();
            } else {
                this.readonly = false;
            }
            this.isLoading = true;
            this.getNames();
            await this.initializeData();
        },
        methods: {
            toggleInput(field) {
                this.currentField = field;
                if (field === 'Species') {
                    this.inputValue = this.Species.join(', ');
                } else if (field === 'behaviors') {
                    this.inputValue = this.behaviors.join(', ');
                }
            },
            onSubmit() {
                if (!this.form.project_id) {
                    this.isDisabled = true;
                    this.$snotify.warning('Please fill in the project name.', {
                        timeout: 2000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    return;
                }
                if (!this.selectedCategoryId) {
                    this.$snotify.warning('Please select a category.', {
                        timeout: 2000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    return;
                }
                let data = {
                    project_id: this.selectedProjectId,
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    category_id: this.selectedCategoryId,
                    is_species_usable: this.form.is_species_usable,
                    is_behavior_usable: this.form.is_behavior_usable,
                };
                if (this.currentField === 'Species') {
                    data.species = this.inputValue;
                } else if (this.currentField === 'behaviors') {
                    data.behavior = this.inputValue;
                }
                API.addEditSighting2Info(
                    data,
                    (data) => {
                        this.isDisabled = false;
                        this.$v.form.$reset();
                        this.$snotify.success(data.message, {
                            timeout: 2000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        this.inputValue = '';
                        this.currentField = '';
                        this.getSighting2Info(this.selectedProjectId, this.selectedCategoryId);
                    },
                );
            },
            getNames() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                };
                API.getProjectName(
                    data,
                    response => {
                        this.projectOptions = response.data.map(project => ({
                            id: project.id,
                            name: project.project_name
                        }));
                        if (this.projectOptions.length > 0) {
                            this.selectedProjectId = this.projectOptions[0].id;
                            this.getProjectStyleData(this.selectedProjectId);
                            this.getSighting2Info(this.selectedProjectId, this.selectedCategoryId);
                            this.toggleInput();
                        }
                    },
                    err => {
                        console.error('Error fetching project names:', err);
                    }
                );
            },
            async initializeData() {
                let data = {
                    id: this.form.client_id
                };
                await API.get_client(
                    data,
                    data => {
                        this.form.client_url = data.url;
                        this.getSyncCategories(this.selectedProjectId);
                    },
                    err => {}
                );
            },

            getSyncCategories(projectId) {
                const data = {
                    client_url: this.form.client_url,
                    project_id: projectId
                };
                API.getSyncCategories(
                    data,
                    data => {
                        this.categories = data.data;
                        if (this.categories.length === 0) {
                            this.form.category_id = '';
                        }
                    },
                    err => {}
                );
            },
            getProjectStyleData(projectId) {
                API.getProjectSetup(
                    { projectId },
                    response => {
                        const project = response.data;
                        this.form.project_id = project.project_id;
                        this.form.survey_style_id = project.survey_style_id;
                        this.form.survey_style_name = project.survey_style ? project.survey_style.name : '';
                    },
                    err => {
                        console.error('Error fetching project details:', err);
                    }
                );
            },

            getSighting2Info(projectId, categoryId) {
                const data = {
                    projectId,
                    categoryId
                };
                API.getSighting2Info(
                    data,
                    response => {
                        if (response.status === 200) {
                            const sightingData = response.data;
                            const speciesData = sightingData.find(item => item.key === 'species');
                            const behaviorData = sightingData.find(item => item.key === 'behavior');

                            if (speciesData && speciesData.values) {
                                this.form.is_species_usable = speciesData.is_usable;
                                this.Species = speciesData.values.split(',').map(s => s.trim());
                            } else {
                                this.Species = [];
                                this.form.is_species_usable = false;
                            }

                            if (behaviorData && behaviorData.values) {
                                this.behaviors = behaviorData.values.split(',').map(b => b.trim());
                                this.form.is_behavior_usable = behaviorData.is_usable;
                            } else {
                                this.behaviors = [];
                                this.form.is_behavior_usable = false;
                            }
                        } else {
                            console.error('Error fetching sighting information:', response.message);
                        }
                    },
                    err => {
                        console.error('Error fetching sighting information:', err);
                    }
                );
            },
            handleCategoryChange() {
                if (this.selectedProjectId && this.selectedCategoryId) {
                    this.getSighting2Info(this.selectedProjectId, this.selectedCategoryId);
                }
            },
            onProjectChange(selectedProjectId) {
                if (selectedProjectId) {
                    this.getProjectStyleData(selectedProjectId);
                    this.toggleInput();
                    this.getSyncCategories(selectedProjectId);
                }
            },
        }, 
        watch: {
            selectedProjectId(newProjectId) {
                this.getSighting2Info(newProjectId, this.selectedCategoryId);
            },
            selectedCategoryId(newCategoryId) {
                this.getSighting2Info(this.selectedProjectId, newCategoryId);
            }
        },
        computed: {
            SpeciesName: {
                get() {
                    return this.Species.join(', ');
                },
                set(newValue) {
                    this.Species = newValue.split(',').map(name => name.trim());
                }
            },
            behaviorNames: {
                get() {
                    return this.behaviors.join(', ');
                },
                set(newValue) {
                    this.behaviors = newValue.split(',').map(name => name.trim());
                }
            },
        },
    }
</script>

<style>
    .btn-duplicate {
        width: 80px !important;
        padding-left: 10px !important;
        background: #5D9E12;
        border-color: #5D9E12;
    }
    .checkbox-radio {
        display: flex;
        gap: 35px;
    }
    .checkbox-radio label {
        padding-left: 7px;
    }
    .card-body.new-card-body select {
        border: 1px solid #000;
        border-radius: 0;
        height: 46px;
    }
    .project-checkbox label {
        padding-left: 15px;
    }
    .project-checkbox textarea {
        border: 1px solid #000 !important;
        height: 250px;
        outline: none;
        box-shadow: none;
        border-radius: 0;
    }
    @media (max-width: 375px) {
        .row .card .new-card-body {
            padding: 1.25rem 1.437rem !important;
        }
    }
</style>
